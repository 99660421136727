import { useEffect } from "react";
import { SchedulingLinkDto } from "../reclaim-api/SchedulingLinks";
import { isEven } from "./numbers";

const OrigDate = Date;

/* Time-Travel */

// Reset global Date
export function unmockDate() {
  if (!Date["mocked"]) return;
  Date = OrigDate;
}

export function mockDate(offset: number) {
  // Reset mock if offset changed
  if (!!Date["mocked"]) {
    if (offset === Date["offset"]) return;
    unmockDate();
  }

  // Replace global Date w/ Proxy
  Date = new Proxy(OrigDate, {
    // offset new Date()
    construct: function (Target, args: Parameters<DateConstructor>): Date {
      // props to https://stackoverflow.com/a/43160428/671457
      if (args.length === 0) return new Target(OrigDate.now() - offset);
      return new Target(...args);
    },

    get: function (Target, prop, receiver) {
      // used to detect mocked Date
      if (prop === "mocked") return true;
      if (prop === "offset") return offset;

      // offset Date.now()
      if (prop === "now") {
        return () => new Target(OrigDate.now() - offset).getTime();
      }

      return Reflect.get(Target, prop, receiver);
    },
  });
}

export function useAnnounceMount(name: string, ...data: unknown[]): void {
  function getArgs(action: string): unknown[] {
    const args: unknown[] = [`${name} ${action}`];
    if (data) args.push(...data);
    return args;
  }

  useEffect(() => {
    console.log(...getArgs("mounted"));
    return () => console.log(...getArgs("unmounted"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export const hashNum = (val: unknown): number => {
  const str = JSON.stringify(val);
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0;
  }
  return hash;
};

export const hashColor = (val: unknown): string => {
  const hash = hashNum(val);
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return ("#" + "00000".substring(0, 6 - c.length) + c) as string;
};

export const hashBoolean = (val: unknown): boolean => isEven(hashNum(val));

export const randomInArray = <T>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)];

export const mockSchedulingLinkDto = (): SchedulingLinkDto => ({
  id: "8d8447d7-c93d-48d1-951d-fe6365944244",
  title: "30 minute meeting",
  description:
    "A long description of my meeting link. Sit dicta perspiciatis solutall am ut saepe et id facere omnis. Qui aspernatur necess itatibus quidem et in vel.",
  enabled: true,
  organizer: {
    avatarUrl: "https://lh3.googleusercontent.com/a-/AFdZucojejfHkMEA5O6Sp_81QYNFKT1C1OV93XJzyhsH=s96-c",
    email: "reclaim.office2@gmail.com",
    name: "Michael Scott",
    userId: "ee8be6dc-7fc0-435c-9bb1-8d12c7c0b581",
  },
  timePolicy: "MEETING",
  daysActive: null,
  windowStart: null,
  windowEnd: null,
  durationMin: 30,
  durationMax: 30,
  daysIntoFuture: 14,
  startDate: null,
  endDate: null,
  priority: "DEFAULT",
  conferenceType: null,
  iconType: "BOLT",
});

export const mockSchedulingLinkDtos = (): SchedulingLinkDto[] => {
  const archetype = mockSchedulingLinkDto();
  const description = "A short description of my meeting link.";

  return [
    { ...archetype, title: "Short Meeting", description, durationMin: 15, durationMax: 30, iconType: "COFFEE" },
    archetype,
    { ...archetype, title: "Priority Meeting", description, durationMin: 60, durationMax: 60, iconType: "LIGHT" },
  ];
};